.i {
    display: flex;
    height: 100vh;
}

.i-left {
    flex: 1;
}

.i-right {
    flex: 1;
    position: relative;
}

.i-left-wrapper {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.i-intro {
    font-size: 30px;
    font-weight: 300;
}

.i-name {
    font-size: 60px;
}

.i-title {
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25% {
        transform: translateY(-50px);
    }
    50% {
        transform: translateY(-100px);
    }
    75% {
        transform: translateY(-150px);
    }
    100% {
        transform: translateY(-200px);
    }
}

.i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #59b256;
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Add margin-bottom */
}

.i-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.i-bg {
    clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: #59b256;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.i-social {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.i-social-icon {
    font-size: 24px; /* Adjust for SVG icons */
    color: #333; /* Adjust if necessary */
    margin: 0 10px;
    cursor: pointer; /* Makes it clear they are clickable */
}
.i-social-icon img {
    width: 100px;  /* Set the width to a fixed size */
    height: auto; /* Set height to auto to maintain the aspect ratio */
    vertical-align: middle; /* Aligns the icon vertically in line with any text or other elements */
}


@media screen and (max-width: 768px) {
    .i-left-wrapper {
        padding: 20px;
    }

    .i-intro {
        font-size: 20px;
    }

    .i-name {
        font-size: 40px;
    }

    .i-title-item {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .i {
        flex-direction: column;
    }

    .i-left-wrapper {
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .i-desc {
        display: none;
    }
}
