.p {
    width: 100%;
    height: 40vh; /* Set a fixed height for all cards */
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
  }
  
  .p-browser {
    height: 20px;
    background-color: rgb(243, 242, 242);
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
  }
  
  .p-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
  }
  
  .p-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 10s ease;
  }
  
  .p:hover .p-img {
    transform: translateY(-100%);
  }
  
  .p-tech {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }
  
  .p-tech-item {
    background-color: #f1f1f1;
    color: #333;
    font-size: 12px;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .p-details {
    padding: 10px;
    text-align: center; /* Center align the details */
  }
  
  .p-description {
    color: #333;
    font-size: 14px; /* Adjust the font size as needed */
    margin-top: 5px; /* Add space above the description */
  }
  
  @media screen and (max-width: 768px) {
    .p {
      width: 40%;
      height: 20vh;
    }
  }
  
  @media screen and (max-width: 480px) {
    .p {
      width: 100%;
      height: 20vh;
    }
  }
  