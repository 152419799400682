.pl {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .pl-texts {
    width: 65%;
  }
  
  .pl-title {
    font-size: 50px;
    font-weight: 600;
  }
  
  .pl-desc {
    margin: 20px 0px;
  }
  
  .pl-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  
  .product-wrapper {
    width: calc(30% - 20px); /* Adjust the width based on the number of cards you want to display per row */
    margin: 20px 0;
    position: relative;
  }
  
  .p {
    width: 100%;
  height: auto;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px;
  overflow: hidden;
  }
  
  .p:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  
  .p-img {
    width: 100%;
    height: auto; /* Ensure images maintain aspect ratio */
    object-fit: cover;
  }
  
  .technologies {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  }
  
  .tech-item {
    display: inline-block;
    background-color: #ddd;
    color: #333;
    font-size: 12px;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  @media screen and (max-width: 768px) {
    .pl {
      padding: 30px;
    }
  
    .pl-texts {
      width: 100%;
    }
  
    .product-wrapper {
      width: calc(50% - 20px); /* Adjust for two cards per row on smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .product-wrapper {
      width: calc(100% - 20px); /* Adjust for one card per row on even smaller screens */
    }
  }
  