.skills-section {
  padding: 50px 0;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

.category-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #555;
}

.skill-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.skill-item {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #777;
  transition: transform 0.3s ease;
}

.skill-item:hover {
  transform: scale(1.1);
}

.dark-mode .skill-item {
  color: #ccc; /* Example color for dark mode items */
}

.dark-mode .skills-section {
  background-color: #333;
  color: #fff;
}