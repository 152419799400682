.cash-calendar-page {
    max-width: 600px; /* Adjust this as needed for overall container width */
    margin: auto;
    padding: 20px;
}

.image-gallery {
    display: flex; /* Changed from grid to flex for side-by-side layout */
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    justify-content: space-between; /* Distributes space between and around items */
    gap: 20px; /* Adds space between images */
}

.image-gallery img {
    flex: 1 1 300px; /* Allows images to grow and shrink as needed with a base width of 300px */
    max-width: 100%; /* Ensures image is not wider than its container */
    height: auto; /* Maintains the aspect ratio of the image */
    object-fit: cover; /* Ensures images cover the area nicely without distortion */
}

ul {
    list-style-type: none; /* Removes bullet points */
}

a {
    color: blue;
    text-decoration: none; /* Removes underline */
}

a:hover {
    text-decoration: underline; /* Adds underline on hover for better interaction feedback */
}

@media (max-width: 768px) {
    .image-gallery {
        flex-direction: column; /* Stacks images vertically on smaller screens */
    }

    .image-gallery img {
        max-width: 100%; /* Full width on smaller screens */
        margin-bottom: 20px; /* Adds space between stacked images */
    }
}
